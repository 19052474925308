import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import { AffiliationForDoctorModel } from './AffiliationForDoctorModel.fragment';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type DoctorAffiliationsToPromptVariables = Types.Exact<{ [key: string]: never; }>;


export type DoctorAffiliationsToPrompt = { __typename?: 'Query', doctorAffiliationsToPrompt: Array<{ __typename?: 'AffiliationForDoctorModel', affiliationId: string, doctorPortalUserId: string, name: string, startDate: string, endDate: string, isExpired: boolean, joinStatus: Types.AffiliationDoctorJoinStatus, services: Array<{ __typename?: 'ServiceModel', patientBookingRequiresRequestProposal: boolean, doctorCanCreateRequestProposal: boolean, isEnabled: boolean, baseServiceType: Types.ServiceType, title: string, description: string, id: string, price: number, durationMinutes?: number | null, providedWithinDays?: number | null, giftType: Types.ServiceGiftType, portalExtension: { __typename?: 'ServicePortalExtensionModel', onHealthServiceId: string }, bookingProviderExtension: { __typename?: 'AffiliationBookingProviderExtensionModel', bookingType: Types.ServiceBookingType } | { __typename?: 'ClinicBookingProviderExtensionModel', bookingType: Types.ServiceBookingType } | { __typename?: 'DoctorBookingProviderExtensionModel', bookingType: Types.ServiceBookingType }, addons: Array<{ __typename?: 'ServiceAddonModel', id: string, type: Types.ServiceAddonType, price: number, providedWithinDays?: number | null }> }> }> };


export const DoctorAffiliationsToPromptDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"DoctorAffiliationsToPrompt"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"doctorAffiliationsToPrompt"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AffiliationForDoctorModel"}}]}}]}},...AffiliationForDoctorModel.definitions]} as unknown as DocumentNode;

/**
 * __useDoctorAffiliationsToPrompt__
 *
 * To run a query within a React component, call `useDoctorAffiliationsToPrompt` and pass it any options that fit your needs.
 * When your component renders, `useDoctorAffiliationsToPrompt` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorAffiliationsToPrompt({
 *   variables: {
 *   },
 * });
 */
export function useDoctorAffiliationsToPrompt(baseOptions?: ApolloReactHooks.QueryHookOptions<DoctorAffiliationsToPrompt, DoctorAffiliationsToPromptVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DoctorAffiliationsToPrompt, DoctorAffiliationsToPromptVariables>(DoctorAffiliationsToPromptDocument, options);
      }
export function useDoctorAffiliationsToPromptLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DoctorAffiliationsToPrompt, DoctorAffiliationsToPromptVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DoctorAffiliationsToPrompt, DoctorAffiliationsToPromptVariables>(DoctorAffiliationsToPromptDocument, options);
        }
export type DoctorAffiliationsToPromptHookResult = ReturnType<typeof useDoctorAffiliationsToPrompt>;
export type DoctorAffiliationsToPromptLazyQueryHookResult = ReturnType<typeof useDoctorAffiliationsToPromptLazyQuery>;
export type DoctorAffiliationsToPromptQueryResult = Apollo.QueryResult<DoctorAffiliationsToPrompt, DoctorAffiliationsToPromptVariables>;